
.App {
  background-color: #ffffff;
  overflow-x: hidden;
}

.order {
  padding: 15px 15px 15px 50px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 120px);
  grid-auto-rows: 60px;
  grid-gap: 10px;
}

.selected {
  background-color: #dddddd;
}

.marques {
  margin-left: 30px;
}

.Stats {
  flex: 1;
  padding: 15px 15px 15px 50px;
  border-top: 2px solid #888888;
}

@media (min-width: 800px) {
  .order {
    grid-template-columns: repeat(auto-fill, 160px);
    grid-auto-rows: 80px;
  }
}