body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.shortcuts {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #f5f7f9;
    width: 30px;
    height: 100vh;
    display: grid;
    align-items: center;
    justify-content: center;
    justify-items: center;
    grid-template-columns: 30px;
    grid-template-rows: repeat(26, 1fr);
    grid-gap: 5px;
  }
.Marque {

}

.HideMarque {
    display: none;
}

.Marque > div.title {
    background: #f5f7f9; /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #f5f7f9 0%,#d7dee3 49%,#e5ebee 50%,#f6f8f9 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f5f7f9', endColorstr='#f6f8f9',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    padding: 8px 20px;
    width: 92%;
    font-weight: bold;
}

.cars {
    overflow-y: hidden;
    overflow-x: auto;
    height: 120px;
    padding: 15px 30px 15px 15px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 120px);
    grid-auto-rows: 120px;
    grid-gap: 15px;
    grid-auto-flow: column;
}

.cars::-webkit-scrollbar {
    display: none;
}

@media (min-width: 800px) {
    .cars {
        grid-template-columns: repeat(auto-fill, 160px);
        height: 160px;
        grid-auto-rows: 160px;
    }
}
.Car {
    position: relative;
    width: 100px;
    border-radius: 5px;
    border: 3px solid #000000;
    padding: 10px;
    font-size: 14px;
    background-color: hsl(0, 100%, 100%);

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 2fr 1fr;
    grid-gap: 10px;
    grid-template-areas: "title title" "class year";
}
  
.title { grid-area: title; font-size: 14px; font-weight: bold; overflow: hidden; }

.class { grid-area: class; align-self: flex-end; }

.year { grid-area: year; align-self: flex-end; justify-self: flex-end; font-size: 18px; font-weight: bold; }

.Owned {
    background-color: hsl(113, 100%, 80%);
    color: rgb(165, 207, 137);
}

.Class-D {
    border-color: #1BA9E9;
}
.Class-C {
    border-color: #E1BA0D;
}
.Class-B {
    border-color: #DB511B;
}
.Class-A {
    border-color: #C53419;
}
.Class-S1 {
    border-color: #882FF2;
}
.Class-S2 {
    border-color: #882FF2;
}
.Class-X {
    border-color: #19A83C;
}

.FE:before {
    content: " ";
    position: absolute;
    z-index: 0;
    top: -8px;
    left: -8px;
    right: -8px;
    bottom: -8px;
    border: 3px solid #ff0000;
    border-radius: 5px;
}
.Class-D.FE:before {
    border-color: #1BA9E9;
}
.Class-C.FE:before {
    border-color: #E1BA0D;
}
.Class-B.FE:before {
    border-color: #DB511B;
}
.Class-A.FE:before {
    border-color: #C53419;
}
.Class-S1.FE:before {
    border-color: #882FF2;
}
.Class-S2.FE:before {
    border-color: #882FF2;
}
.Class-X.FE:before {
    border-color: #19A83C;
}

@media (min-width: 800px) {
    .Car {
        width: 140px;
    }
}

.App {
  background-color: #ffffff;
  overflow-x: hidden;
}

.order {
  padding: 15px 15px 15px 50px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 120px);
  grid-auto-rows: 60px;
  grid-gap: 10px;
}

.selected {
  background-color: #dddddd;
}

.marques {
  margin-left: 30px;
}

.Stats {
  flex: 1 1;
  padding: 15px 15px 15px 50px;
  border-top: 2px solid #888888;
}

@media (min-width: 800px) {
  .order {
    grid-template-columns: repeat(auto-fill, 160px);
    grid-auto-rows: 80px;
  }
}
