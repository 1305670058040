.Marque {

}

.HideMarque {
    display: none;
}

.Marque > div.title {
    background: #f5f7f9; /* Old browsers */
    background: -moz-linear-gradient(-45deg, #f5f7f9 0%, #d7dee3 49%, #e5ebee 50%, #f6f8f9 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, #f5f7f9 0%,#d7dee3 49%,#e5ebee 50%,#f6f8f9 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #f5f7f9 0%,#d7dee3 49%,#e5ebee 50%,#f6f8f9 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f5f7f9', endColorstr='#f6f8f9',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    padding: 8px 20px;
    width: 92%;
    font-weight: bold;
}

.cars {
    overflow-y: hidden;
    overflow-x: auto;
    height: 120px;
    padding: 15px 30px 15px 15px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 120px);
    grid-auto-rows: 120px;
    grid-gap: 15px;
    grid-auto-flow: column;
}

.cars::-webkit-scrollbar {
    display: none;
}

@media (min-width: 800px) {
    .cars {
        grid-template-columns: repeat(auto-fill, 160px);
        height: 160px;
        grid-auto-rows: 160px;
    }
}